body{
    background-color: #27293d;
    color:white;
}

.modQueueLink{
    color: white;
    font-size: x-large;
}

.queueModWrapper{
    margin-top: 2em;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
}

.modButtons{
    display:flex;
    flex-direction: row;
    justify-content: left;
    align-items:left;
}
.queueWrapper{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.assetsToReview{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.libraryWrapper{
    display: flex;
    flex-direction: column;
}

.libraryPageWrapper{
    margin-top: 4em;
    display:flex;
    flex-direction: column;
    align-items: left;
    margin-left: 10%;
}

.editField{
    margin-right: .5em;
}

.galleryWrapper{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.inviteForm{
    background-color: rgb(16, 16, 43);
    top: 50%;
    width: 30%px;
    overflow: hidden;
    border-radius: 15px;
    margin-right: auto;
    padding: 80px;
    border-style:groove;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
}

.inviteField{
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    align-items: baseline;
}

.libraryInvite{
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
}

.inviteUser{
    margin-top:.4em;
}
/*
Asset
*/

.viewAssetWrapper{
    margin-top: 4em;
    display:flex;
    flex-direction: column;
    align-items: left;
    margin-left: 10%;
}

.assetWrapper{
    margin-left:3em;
    display:flex;
    flex-direction: row;
    width:60%;
}

.contextWrapper{
    margin-left:2em;
}

/*
Profile
*/
.userProfile{
    margin-top: 4em;
    margin-bottom: 4em;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.userName{
    text-decoration: underline;
    font-weight: bold;
}

.userBio{
    margin-top: .5em;
    font-weight: bold;
}

.userLibraries{
    display: flex;
    margin-top:2%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.userGroups{
    display: flex;
    margin-top:2%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.profileCreations{
    display: flex;
    margin-top:2%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.profileCreationsTitle{
    margin-left: 10%;
}

/*
Comments & Creation Buttons
*/
.creationButtons{

}

.commentText{

}

.commentMaker{
    margin-right: 2%;
}

.commentItself{
    background-color: rgb(6, 6, 17);
    margin-top: .5%;
    margin-bottom: 4%;
    display:flex;
    flex-direction: row;
}

.allComments{
    background-color: rgb(16, 16, 43);
    margin-top: 2em;
    width: 30%;
    margin-left: 5%;
    padding-left: 1em;
    padding-right: 1em;
    padding-top:1em;
    padding-bottom:.5em;
    border-style: groove;

}

/*
404
*/
.fourZeroFourWrapper{
    margin-top: 5em;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.fourZeroFour{
    color: white;
    font-size:600%
}

.notFoundText{
    margin-top: .5em;
    color: white;
}

/*
Index
*/

.homePageWrapper{
    margin-top: 2em;
    margin-left: 15%;
}

.imageCardWrapper{
    display: flex;
    flex-wrap: column;
    width: 80%;
    justify-content: center;
    align-items: center;
}
.imageCardInfo{
    display:flex;
    flex-direction: column;
    align-items: center;
}
.cardImage{
    padding-top: 1em;
    width: 15em;
    margin-left:2em;
    margin-right:2em;
}

.imageViewHere{
    text-decoration: none;
    color: white;
    border-style: groove;
    border-color: black;
    border-radius: 6px;
    padding-left: .5em;
    padding-right: .5em;
    margin-top:.5em;
}

.imageCard{
    background-color: rgb(16, 16, 43);
    margin: 0, auto;
    align-items: center;
    justify-content: center;
    border-style: groove;
    padding: .2em;
    margin-top: .5em;
    width:20em;
    margin-left: 2em;
  
}

.imageTitle{
    color:white;
    justify-self: center;

}

/*
    Creation Page
*/
.creationDescriptionAndButtonsWrapper{
    display: flex;
    flex-direction: column;
}
.creationPageWrapper{
    margin-top: .5em;
    display:flex;
    flex-direction: column;
    align-items: left;
    margin-left: 10%;
}
@media only screen and (max-width: 600px)  {
    .imageCardWrapper{
        display: flex;
        flex-wrap: wrap;
    }
}
.creationWrapper{
    display: flex;
    padding: 15px;
    width: 50%;
    height:auto;
    margin-left: 3%;
}
.creationImage img{
    width:20%;
}

.creationImage{
    width:100%;
    margin-left: 3%;
    border-style: groove;
    border-radius: 10px;    

}

.creationInformation{
    width: 35%;
    margin-left: 4%;
}

.creationTitle{
    color: white;
    font-weight: bold;
}

.creationCreator{
    color:white;
    font-style: italic;
}

.creationDescription{
    color:white;
    padding-top: .5em;
    overflow: scroll;
}
/*
    NavBar
*/

.navbar{
    background-color: #161724;
    padding-bottom: .75em;
}

.navlink{
    font-size: large;
    margin-left: .5em;
    margin-right: .5em;
    text-decoration: none;
    color: white;
}
/*
    Forms, and other such associated stylings.
*/

.tiempo{
    margin-left: .5em;
    margin-right: .5em;
}

.permCheck{
    margin-right:.5em;
}

.formTitle{
    padding-bottom: .5em;
}

.formWrapper{
    margin-top:2%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.indexLink{
    margin-right:12%;
    font-size:x-large;
    margin-bottom: 1rem;
}

.registerHere{
    color:white;
    margin-top:1rem;
}

.form{
    background-color: rgb(16, 16, 43);
    left: 50%;
    top: 50%;
    width: 30%px;
    overflow: hidden;
    border-radius: 15px;
    margin-left:auto;
    margin-right:auto;
    padding: 80px;
    border-style:groove;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
}

.inputSection{
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.inputButton{
    padding-top: .5em;
    padding-bottom: .5em;
    padding-left: 2em;
    padding-right: 2em;
    margin-left: 8em;
    margin-top: 2rem;
    border-radius: 10px;
    cursor: pointer;
    background-color: #27293d;
    color: white;
    
}

.uploadHereWrapper{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top:4em;
}
